import React from "react";

import logo from "../assets/logo.svg";
import arkose from "../assets/AL.jpeg";
import auth0 from "../assets/auth0-logo.png";

const Hero = () => (
  <div >
    <img className="mb-3 app-logo" src={arkose} alt="React logo" width="500" />
    <img className="mb-3 app-logo" src={auth0} alt="React logo" width="500" />
    

  </div>
);

export default Hero;
